import React, { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import mainTheme from 'config/theme/main-theme';
import 'config/font/stylesheet.css';
import 'config/styles/main.css';
import { Footer, Navbar } from 'templates/layout';
import { NextRouter, useRouter } from 'next/router';
import { hideNavBgRoutes } from 'data/routes';
import { loadReCaptcha } from 'react-recaptcha-v3';
import AOS from 'aos';
import { RECAPTCHA_KEY } from 'config/env';
import 'aos/dist/aos.css';
import 'utils/sentry/sentry-config';

const MyApp: FC = ({ Component, pageProps }: any) => {
  const [showNavbarBg, setShowNavbarBg] = useState<boolean>(false);
  const { asPath }: NextRouter = useRouter();
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    AOS.init({
      once: true,
      easing: 'ease-out-quad',
      duration: 200,
    });
  }, []);

  useEffect(() => {
    const isRouteExists = hideNavBgRoutes.some((path) => path === asPath.split('#')[0]);
    isRouteExists ? setShowNavbarBg(false) : setShowNavbarBg(true);
  }, [asPath]);

  useEffect(() => {
    loadReCaptcha(RECAPTCHA_KEY);
  }, []);

  return (
    <>
      <Head>
        <title>Patron Trucking</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <article id="main-article">
          <Navbar showNavbarBg={showNavbarBg} />
          <main>
            <Component {...pageProps} />
          </main>
          <Footer />
        </article>
      </ThemeProvider>
    </>
  );
};

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};

export default MyApp;
