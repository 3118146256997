import { createMuiTheme } from '@material-ui/core/styles';

const mainTheme = createMuiTheme({
  spacing: 5,
  palette: {
    primary: {
      main: '#2E5ECC',
      dark: '#232D3D',
    },
    error: {
      main: '#FE3E51',
      light: '#FFEBED',
    },
    secondary: {
      main: '#DCDFE4',
      dark: '#B4BBC6',
      light: '#A4ADB5',
    },
    text: {
      primary: '#232D3D',
      secondary: '#FFFFFF',
    },
    success: {
      main: '#66B065',
    },
    common: {
      black: '#000',
    },
  },
  typography: {
    fontFamily: ['HelveticaNeueCyr', 'Helvetica Neue', 'sans-serif'].join(','),
    subtitle1: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 'normal',
      '& span': {
        fontWeight: 700,
      },
    },
    subtitle2: {
      fontSize: 18,
      fontWeight: 400,
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      '@media(min-width: 1280px)': {
        fontSize: 18,
      },
    },
    h1: {
      fontWeight: 400,
      fontSize: 36,
      lineHeight: 1.08,
      '& span': {
        fontWeight: 700,
      },
      '@media(min-width: 600px)': {
        fontSize: 42,
        lineHeight: 1.18,
      },
      '@media(min-width: 960px)': {
        fontSize: 60,
      },
    },
    h2: {
      fontWeight: 400,
      fontSize: 20,
      '& span': {
        fontWeight: 700,
      },
      '@media(min-width: 960px)': {
        fontSize: 24,
      },
      '@media(min-width: 1280px)': {
        fontSize: 32,
      },
    },
    h3: {
      fontWeight: 400,
      fontSize: 18,
      '& span': {
        fontWeight: 700,
      },
      '@media(min-width: 375px)': {
        fontSize: 20,
      },
      '@media(min-width: 600px)': {
        fontSize: 24,
      },
    },
    h4: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 1.5,
      '& span': {
        fontWeight: 700,
      },
      '@media(min-width: 375px)': {
        fontSize: 16,
      },
      '@media(min-width: 600px)': {
        fontSize: 20,
      },
    },
  },
  overrides: {
    // @ts-ignore
    MuiPickersDay: {
      daySelected: {
        transition: 'all 200ms',
        borderRadius: 0,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#F4F7F8',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        '&$error': {
          backgroundColor: '#FFEBED !important',
        },
      },
      input: {
        fontSize: 18,
        padding: '10px 15px',
        color: '#232D3D',
      },
    },
    MuiSelect: {
      root: {
        display: 'flex',
        alignItems: 'flex-end',
      },
    },
    MuiAppBar: {
      root: {
        transition: 'all 400ms',
        boxShadow: 'none',
      },
      colorSecondary: {
        backgroundColor: '#fff',
        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiListItem: {
      root: {
        paddingTop: 5,
        paddingBottom: 5,
      },
    },
    MuiContainer: {
      fixed: {
        '@media(min-width: 1920px)': {
          maxWidth: 1280,
        },
      },
      root: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 0,
        boxShadow: '0px 4px 48px rgba(0, 0, 0, 0.15)',
        padding: 40,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        transition: 'all 0.6s',
        padding: '15px 40px',
        fontSize: 18,
        textTransform: 'initial',
        '&:hover': {
          transform: 'translateY(-5px)',
        },
        '& .MuiButton-label': {
          lineHeight: 'normal',
          height: 21,
          marginTop: 3,
          fontWeight: 400,
        },
        '&$disabled': {
          backgroundColor: '#A4ADB5 !important',
          color: 'white !important',
        },
      },
      sizeSmall: {
        padding: '10px 30px',
        fontSize: 14,
      },
      containedPrimary: {
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#2E5ECC',
          boxShadow: '0px 6px 15px rgba(46, 94, 204, 0.7)',
        },
      },
      outlined: {
        padding: '13px 40px',
        marginTop: 1,
        boxShadow: '0px 0px 0px 1px #fff',
        color: '#fff',
        '&:hover': {
          color: '#2E5ECC',
          backgroundColor: '#fff',
          boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.5)',
        },
      },
      outlinedPrimary: {
        '&:hover': {
          color: '#fff',
          backgroundColor: '#2E5ECC',
          boxShadow: '0px 6px 15px rgba(46, 94, 204, 0.7)',
        },
      },
      outlinedSecondary: {
        border: '1px solid #000',
        color: '#000',
        '&:hover': {
          color: '#fff',
          backgroundColor: '#2E5ECC',
          boxShadow: '0px 0px 0px 2px #000',
        },
      },
    },
  },
});
export default mainTheme;
