import * as Sentry from '@sentry/node';
import { isBrowser } from '@unly/utils';
import {
  ENV_IS_PRODUCTION,
  ENV_SENTRY_APP_STAGE,
  ENV_SENTRY_BUILD_TIME,
  ENV_SENTRY_COMMIT_SHA,
  ENV_SENTRY_DSN,
} from 'config/env';

Sentry.init({
  dsn: ENV_SENTRY_DSN,
  enabled: ENV_IS_PRODUCTION,
  environment: ENV_SENTRY_APP_STAGE || 'Default',
  release: ENV_SENTRY_COMMIT_SHA || 'Not defined',
});

if (!ENV_SENTRY_DSN && ENV_IS_PRODUCTION) {
  console.error('Sentry DSN not defined');
}

Sentry.configureScope((scope) => {
  scope.setTag('runtimeEngine', isBrowser() ? 'Browser' : 'Server');
  scope.setTag('buildTime', ENV_SENTRY_BUILD_TIME);
});

export default Sentry;
